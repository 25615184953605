<template>
  <div>
    <article>
      <h3 class="center">{{appName}}隐私政策</h3>
      <p style="text-align: right; font-weight: bold">生效时间：2023年【11】月【09】日</p>
      <section>
        <h4>【引言】</h4>
        <p>
          欢迎您使用成都开心音符科技有限公司（以下简称“我们”）提供的{{appName}}相关产品及/或服务！我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息安全可控。基于此，我们制定本《{{appName}}隐私政策》（下称"本政策"或"本隐私政策")，帮助您充分了解在您使用我们产品及/或服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
        </p>
        <p>
          欢迎您使用我们的产品及/在您开始使用我们的产品及/或服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以粗体/粗体下划线标识的条款，确保您充分理解和同意之后再开始使用。除本政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示等）、功能更新说明等方式，向您说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明等构成本政策的一部分，并与本政策具有同等效力。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。/或服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
        </p>
        <p>
          您同意隐私政策表示您已了解{{appName}}提供的产品及/或服务及运行所需的必要个人信息，并给予我们相应的个人信息处理的授权同意，但并不代表您已单独同意开启附加功能、处理非必要个人信息，相关附加功能的开启、处理非必要个人信息以及处理敏感个人信息，我们会根据法律法规的要求，单独征求您的同意。
        </p>
        <h4>本隐私政策将帮助您了解以下内容：</h4>
        <h5>一、适用范围</h5>
        <h5>二、信息收集及使用</h5>
        <h5>三、信息的共享及转移、公开</h5>
        <h5>四、您的权利</h5>
        <h5>五、信息的存储</h5>
        <h5>六、政策的更新</h5>
        <h5>七、未成年人保护</h5>
        <h5>八、联系我们</h5>
        <h5>附录：相关定义</h5>
        <h5>【正文】</h5>
        <p>
          我们深知用户信息对您的重要性，并会尽全力保护您的用户信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的用户信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的用户信息。请在使用我们的产品（或服务）前，仔细阅读并了解本隐私政策。
        </p>
      </section>
      <section>
        <h4>一、适用范围</h4>
        <p>
          本政策适用于我们通过{{appName}}小程序向您提供的各项产品及/或服务。我们及关联公司就其向您提供的产品及/或服务单独设有隐私政策的，则相应产品及/或服务适用相应隐私政策。除本政策说明的相关信息收集使用活动外，本政策不适用于第三方向您提供的其他服务，其他服务适用其向您另行说明的个人信息收集使用规则。
        </p>
      </section>
      <section>
        <h4>二、信息收集及使用</h4>
        <h4>在您使用我们的产品及/或服务时，我们需要或可能需要收集和使用的您的个人信息包括如下两种：</h4>
        <p>
          1、为实现向您提供我们产品及/或服务的基本功能，及履行法律法规规定的义务，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；
        </p>
        <p>
          2、为实现向您提供我们产品及/或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。我们在此特别提醒您：我们致力于打造多样的产品及/或服务以满足您的需求。因我们向您提供的产品及/或服务种类众多，且不同用户选择使用的具体产品及/或服务范围存在差异，故基本功能或附加功能及对应收集使用的个人信息类型、范围会有所区别，请以具体的产品及/或服务功能为准。
        </p>
        <h4>
          您可以通过<a :href="PersonalInfoListUrl">《个人信息收集清单》</a
          >快速了解我们如何收集您的个人信息。我们会为您提供的各项具体功能场景包括:
        </h4>
        <h5>（一）账号注册/登录</h5>
        <p>
          【账号注册】当您注册我们的产品与/或服务时，为了落实国家法律法规的有关实名认证的要求，保障您的账号安全，您需要向我们提供您的手机号码并进行验证，用于创建账号。
        </p>
        <p>【账号登录】当您使用注册账号登录我们的产品与/或服务时，您需要向我们提供您的手机号码并进行验证。</p>
        <p>
          【第三方授权登录】当您使用第三方平台（例如：微信等）的账号登录时，您授权我们获取您登录的该第三方账号下的相关信息（包括：手机号等，具体以您的授权内容为准），用于与{{appName}}账号绑定，使您可以直接登录并使用相关产品与/或服务。为了满足相关法律法规的网络实名制要求，您使用第三方平台账号授权登录我们的产品与/或服务时，首次授权需要绑定您的手机号码。
        </p>
        <p>上述信息属于上述功能的必要信息，若您不提供这类信息，您可能无法正常注册/登录</p>
        <h5>（二）浏览/生成音乐/分享/下载</h5>
        <p>
          当您使用{{appName}}功能时，为向您提供一致化的服务，我们需要收集您的<span class="bold"
            >设备信息、日志信息。</span
          >前述日志信息包括：<span class="bold"
            >您的操作、行为日志信息，包括您的浏览记录、生成音乐、分享记录、下载记录访问日期。</span
          >此类信息属于该功能的必要信息，若您不提供这类信息，您可能无法正常使用上述功能。您在使用下载功能时，我们会请求您的存储权限。
        </p>
        <h5>（三）写歌</h5>
        <p>
          当您使用{{appName}}功能时，您可以通过对话框输入<span class="bold"
            >歌曲生成的指令（例如：写一首歌给XXX，主题为：XXXXX）、</span
          >选择曲风和演唱歌手。我们会自动接收、记录您自愿输入的指令信息、选择的曲风信息和演唱歌手信息，并使用这些信息向您输出内容。
        </p>
        <p>
          请您注意，您是否在指令中提供个人信息、提供何种个人信息完全由您个人决定。如您拒绝我们收集和处理您的个人信息，请您谨慎在指令中输入您的个人信息。
        </p>
        <p>
          我们理解，您输入的指令可能包含他人的个人信息，这种情况下请您务必在提供前取得他人的合法授权，避免造成他人个人信息的不当泄露。您不应未经授权提供他人的个人信息。
        </p>
        <h5>（四）歌手</h5>
        <p>
          歌手是基于您主动录制的原始音频，通过生成合成类算法进行训练，生成基于您音色的歌手（算法模型）的功能，您可选择使用该音色演唱写歌生成的歌曲。当您生成歌手时，我们会收集您填写的歌手艺名和用于生成歌手过程中<span
            class="bold"
            >主动录制的原始音频信息</span
          >进行处理，在此过程中，我们需要调用您设备的<span class="bold">麦克风权限。</span
          >我们会按照法律的要求，对您的信息在收集、传输和存储的过程中采取严格的加密措施，以确保您的信息安全。我们仅在生成基于您音色的歌手的范围内提供音频处理服务，不会提取识别信息，不会用于识别用途。在生成歌手后，系统将自动删除<span
            class="bold"
            >原始音频信息，</span
          >不予保留。如您不愿我们处理您的音频信息，您可选择使用写歌服务中预设的其他歌手声音，不会影响您使用写歌服务。
        </p>
        <h5>（五）下单与支付</h5>
        <p>
          当您在购买我们的产品及/或服务时，例如生成歌手时，我们需要根据服务类型收集如下部分或全部的<span class="bold"
            >订单信息，</span
          >包括：<span class="bold">交易服务信息、交易金额、下单时间、订单编号、订单状态、支付方式、支付状态，</span
          >我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务。此类信息属于该功能的必要信息，若您不提供这类信息，您可能无法正常参与上述活动。
        </p>
        <h5>（六）客服及争议处理</h5>
        <p>
          当您与我们联系或提出争议纠纷处理申请时，为了保障您的账号及系统安全，我们需要您提供必要的个人信息以核验您的会员身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括账号信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。
        </p>
        <h5>（七）为您提供安全保障</h5>
        <p>
          为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身安全、财产安全、账号安全、交易安全，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入、恶意程序等安全风险，更准确地识别违反法律法规或{{appName}}相关协议、规则的情况，我们会收集您的设备信息，包括<span
            class="bold"
            >设备类型、设备型号、设备品牌、分辨率，操作系统信息，</span
          >来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
        </p>
        <h5>（八）其他附加服务</h5>
        <p>
          (1)基于系统权限的服务为向您提供更便捷、更优质的产品及/或服务，努力提升您的体验，我们在向您提供的服务中可能会通过开启系统权限以收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用{{appName}}的基本服务，但您可能无法获得这些服务给您带来的用户体验。您可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
        </p>
        <p>
          (2)Cookie和同类技术的服务Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您使用我们的服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符（以下简称"Cookie")，以收集、标识和存储您访问、使用本产品时的信息。我们承诺，不会将Cookie用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie主要为了保障产品及/或服务的安全、高效运转，可以使我们确认您账号与交易的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复填写表单、输入搜索内容的步骤和流程。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookie的接受程度或拒绝我们的Cookie。您可能因为这些修改，无法使用依赖于Cookie的服务或相应功能。
        </p>
        <h5>（九）收集和使用个人信息的其他规则</h5>
        <p class="bold">
          1、若您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。
        </p>
        <p class="bold">
          2、除如下情形外，我们对您个人信息的处理均应取得您明确同意（包含依据有关法律、行政法规规定应当取得您的单独同意或者书面同意）：
        </p>
        <p>
          1）
          为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
        </p>
        <p>2） 为履行法定职责或者法定义务所必需；</p>
        <p>3） 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
        <p>4） 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
        <p>5） 依照《个人信息保护法》等法律法规的规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
        <p>6） 法律、行政法规规定的其他情形。</p>
      </section>

      <section>
        <h4>三、信息的共享及转移、公开</h4>
        <h5>（一）共享</h5>
        <p>1、基本原则：</p>
        <p>
          我们提供的产品/服务部分是无法单独完成的，因此我们可能会与合作方共享或委托其处理您的部分个人信息，以保障和优化我们为您提供的产品/服务。
        </p>
        <p>我们与合作方合作时，将遵守以下原则：</p>
        <ul>
          <li>1）合法正当与最小必要原则：数据处理应当具有合法性基础，具有正当的目的，并以实现处理目的最小范围为限。</li>
          <li>2）用户知情权与决定权最大化原则：数据处理过程中充分尊重用户对其个人信息处理享有的知情权与决定权。</li>
          <li>
            3)安全保障能力最强化原则：我们将采取必要措施保障所处理个人信息的安全，审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作协议的有关要求。
          </li>
        </ul>
        <p>
          2、合作方的范围若具体功能和场景中涉及由我们的关联方、第三方提供服务，则合作方范围包括我们的关联方与第三方。
        </p>
        <p>
          3、委托处理我们可能委托授权合作方处理您的个人信息，以便授权合作方代表我们为您提供某些服务或履行职能。我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作方只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作方将您的信息用于我们未委托的用途，其将单独征得您的同意。
        </p>
        <p>
          4、共同处理对于共同处理个人信息的场景，我们会与合作方根据法律规定签署相关协议并约定各自的权利和义务，确保在使用相关个人信息的过程中遵守法律的相关规定、保护数据安全。
        </p>
        <p>5、合作场景：</p>
        <ul>
          <li>1）安全与统计分析</li>
          <li>
            A．保障使用安全：我们非常重视产品及/或服务的安全性，为保障用户的正当合法权益免受不法侵害，我们的合作方可能会使用必要的设备、账号及日志信息。
          </li>
          <li>
            B．分析产品情况：为分析{{appName}}产品及/或服务的稳定性，提供分析服务的合作方可能需要使用服务情况、设备标识信息、应用总体安装使用情况等信息。
          </li>
          <li>
            2）其他合作场景除上述合作场景外，我们还可能会将信息委托给支持我们业务的其他合作方处理，包括受我们委托提供技术基础设施服务、分析我们服务的使用方式或者其他客户服务。为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作方的SDK或其他类似的应用程序。我们会对授权合作方获取有关信息的应用程序接口(API)、SDK进行严格的安全检测，并与授权合作方约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。授权合作方单独取得您同意的除外。<span
              class="bold"
              >上述第三方共享的合作方及共享信息清单，以及{{appName}}中接入的第三方SDK或其他类似的应用程序请见：<a
                :href="SDKListUrl"
                >《第三方信息共享清单》</a
              ></span
            >
          </li>
        </ul>
        <h5>（二）转移</h5>
        <p>
          如果我们因合并、分立、解散、被宣告破产的原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。
        </p>
        <h5>（三）公开披露</h5>
        <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
        <p>1、基于您的主动选择及其他单独同意的情况下，我们可能会公开披露您的个人信息；</p>
        <p>
          2、如果我们确定您出现违反法律法规或严重违反{{appName}}平台相关协议及规则的情况，或为保护{{appName}}平台用户或公众的人身财产安全免遭侵害，我们可能披露关于您的个人信息，包括相关违规行为以及平台已对您采取的措施。
        </p>
        <h5>（四）停止运营</h5>
        <p>
          如果我们停止运营产品及/或服务，将及时停止继续收集您的个人信息。我们会逐一送达通知或以公告的形式向您发送停止运营的告知，并对我们所持有的与已关停的产品及/或服务相关的个人信息进行删除或匿名化处理。
        </p>
        <h5>（五）依法豁免征得您的授权同意的情形</h5>
        <p class="bold">
          请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转移、公开您的个人信息无需征得您的授权同意：
        </p>
        <ul>
          <li>1、根据您的要求订立或履行合同所必需。</li>
          <li>
            2、为履行法定职责或者法定义务所必需：我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息。
          </li>
          <li>3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需。</li>
          <li>4、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息。</li>
          <li>
            5、在合理的范围内处理您自行公开的个人信息，或者其他已经合法公开的个人信息（如合法的新闻报道、政府信息公开等渠道合法公开的个人信息）。
          </li>
          <li>6、法律法规规定的其他情形。</li>
          <p class="bold">
            请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行匿名化的研究、统计分析和预测，用于改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），按照相关法律法规规定，此类数据已不属于个人信息范畴，因此此类处理后数据的使用无需另行征得您的同意。
          </p>
        </ul>
      </section>
      <section>
        <h4>四、您的权利</h4>
        <p>
          您可以通过本政策文末提供的方式联系我们，在核实您的身份后，我们将向您提供信息查阅和管理，我们会在符合法律法规要求的情形下响应您的请求:
        </p>
        <h5>（一）查阅、复制</h5>
        <p>
          您有权查阅、复制存储在{{appName}}小程序中您的个人信息。当您通过账号登录小程序后，可在“我的”页面下查阅、复制您的个人信息。若您无法操作进行查阅、复制的，可以通过本政策所载的客服联系方式与我们联系，我们将协助为您查阅、复制有关个人信息。
        </p>
        <h5>（二）更正、补充</h5>
        <p>
          您有权直接通过{{appName}}小程序更正、补充您的个人信息。当您通过账号登录小程序后，可在“我的”页面下进行更正操作。若您无法操作更正、补充的，可以通过本政策所载的客服联系方式与我们联系，要求我们对错误或不完整的信息做出更正或补充。
        </p>
        <h5>（三）删除</h5>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <ul>
          <li>(1）处理目的已实现、无法实现或者为实现处理目的不再必要；</li>
          <li>(2）我们停止提供产品或者服务，或者保存期限已届满；</li>
          <li>(3）您撤回处理个人信息的同意；</li>
          <li>(4）我们违反法律、行政法规或者违反与您的约定处理个人信息；</li>
          <li>(5）法律、行政法规规定的其他情形。</li>
        </ul>
        <p>
          您可在“我的”页面下进行删除操作。若您无法操作删除的，可以通过本政策所载的客服联系方式与我们联系，要求我们协助您删除个人信息。
        </p>
        <p>
          若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。
        </p>
        <h5>（四）改变/撤回授权范围</h5>
        <p>
          您有权改变您授权同意的范围或撤回授权，您可以通过小程序的设置功能，关闭相应设备权限（如麦克风），以改变/撤回我们获取您设备权限的授权。若您需要改变/撤回其他个人信息授权范围的，可以我们将为您提供相应的协助。
        </p>
        <p>
          当您撤回授权后，我们将不再处理相应的个人信息。但您撤回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。
        </p>
        <h5>（五）注销账号</h5>
        <p>您可通过通过本政策所载的客服联系方式与我们联系，向我们提出注销您账号的请求。</p>
        <p>
          在您主动注销账号之后，我们将停止为您提供产品与/或服务，并根据适用法律的要求删除或匿名化处理您的个人信息。若删除个人信息从技术上难以实现的，我们会进行匿名化处理，或停止除存储和采取必要的安全保护措施之外的处理，但法律法规另有规定的除外。
        </p>
        <h5>（六）响应您的上述请求</h5>
        <p>
          对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们或直接向我们的个人信息保护专职部门发起投诉。我们将在15个工作日内做出答复。为保障您的账号安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份（如增加账号核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
        </p>
      </section>
      <section>
        <h4>五、信息的存储</h4>
        <h5>（一）存储期限</h5>
        <p>
          我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，我们判断个人信息的存储期限主要依据以下标准：
        </p>
        <ul>
          <li>1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；</li>
          <li>2、保证我们为您提供服务的安全和质量；</li>
          <li>3、您是否同意更长的留存期间；</li>
          <li>
            4、是否存在关于保留期限的其他特别约定或法律法规规定。在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的个人信息。
          </li>
        </ul>
        <h5>（二）存储位置</h5>
        <p>
          我们在中华人民共和国境内产生或收集的个人信息，存储在中国境内的服务器。除非取得您的单独同意，或为了履行与您订立的合同，或为了遵循法律法规规定的义务，我们不会向境外提供您的任何个人信息。
        </p>
        <h5>（三）存储安全</h5>
        <p>
          1、数据保护技术措施我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人信息的安全性，在您的浏览器与服务器之间交换数据时受SSL协议加密保护，我们提供HTTPS协议安全浏览方式；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。
        </p>
        <p>
          2、数据保护管理组织措施我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及产品技术的维度提升个人信息的安全性我们已经设置了个人信息保护专职部门，并指定了专人负责儿童个人信息保护。我们通过培训课程和考试，不断加强员工对于保护个人信息重要性的认识。
        </p>
        <p>
          3、个人信息安全事件的响应如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施,以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
        </p>
        <p>
          4、账号安全风险的防范在使用{{appName}}产品与服务时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，不轻信通过其他交易工具进行交易，以免信息被窃取甚至遭遇电信网络诈骗。如您担心自己的个人信息尤其是您的账号发生泄露，请您立即联络我们的客服以便我们根据您的申请采取相应措施。
        </p>
      </section>
      <section>
        <h4>六、政策的更新</h4>
        <p>
          为给您带来更好的产品及/或服务体验，我们持续努力改进我们的产品、服务及技术。在新的服务及业务流程变更时，我们可能会更新我们的隐私政策，但未经您明确同意，我们不会限制您按照本政策所享有的权利。我们会在专门页面上发布对本政策所做的变更。对于重大变更，我们还会提供更为显著的通知（包括我们会通过平台公告或系统消息）向您告知。本政策所指的重大变更包括但不限于：
        </p>
        <p>1、产品的业务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；</p>
        <p>2、业务调整、交易并购引起的隐私政策的主体变更，新主体接收方变更原先的处理目的、处理方式；</p>
        <p>3、个人信息共享或公开披露的主要对象发生变化；</p>
        <p>4、用户个人信息权利及其行使方式发生重大变化；</p>
        <p>5、处理个人信息保护负责人的联络方式及投诉渠道发生变化时；</p>
        <p>
          6、个人信息保护影响评估报告表明产品存在对个人权益有重大影响时。我们还会将本政策的旧版本在专门页面存档，供您查阅。
        </p>
      </section>

      <section>
        <h4>七、未成年人保护</h4>
        <p>
          <span class="bold"
            >我们非常重视对未成年人的个人信息保护。我们没有专门针对未成年人提供产品或服务，在用户使用我们的大部分产品或服务时，我们没有要求用户必须进行身份证件号码的实名认证。因此，我们主要依靠用户在使用特定产品或服务时进行的实名认证或是否选择开启“青少年模式”来判断用户是否属于未成年人。</span
          >若您是十八周岁以下的未成年人，在使用我们的服务前，应事先取得父母或监护人的同意，并在父母或监护人指导下使用我们的服务。若您是十四周岁以下的儿童，在使用我们的服务前，请您通知您的父母或监护人陪同阅读本隐私政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的个人信息。若您是未成年人的父母或其他监护人，请您关注未成年人是否是在取得您的授权同意之后使用我们的服务。如您对未成年人的个人信息有疑问，请与我们的个人信息保护专职部门联系。
        </p>
      </section>

      <section>
        <h4>八、联系我们</h4>
        <p>您可以通过以下方式与我们联系，我们将在15个工作日内回复您的请求：</p>
        <p>1、如您对本政策内容有任何疑问、意见或建议，您可通过在线客服与我们联系；</p>
        <p>
          2、我们还设立了个人信息保护专职部门，您可以通过<a href="mailto:happymusic@rings.net.cn"
            >happymusic@rings.net.cn</a
          >
          与其联系（请您注明与{{appName}}有关，并说明具体事实情况）
        </p>
        <p>办公地址：成都市武侯区天府三街199号太平洋保险金融大厦b区16层</p>
        <p>邮编：610095</p>
        <p>
          需注意的是，我们可能不会回复与本政策或您的个人信息权利无关的问题。如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
        </p>
      </section>
      <section>
        <h4>附录：相关定义</h4>
        <p>{{appName}}：指成都开心音符科技有限公司运营的{{appName}}，载体包括但不限于网页、App应用程序、小程序等。</p>
        <p>个人信息：以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</p>
        <p>
          敏感个人信息：敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
        </p>
        <p>儿童：指不满十四周岁的未成年人。</p>
        <p>
          设备信息：包括设备参数及系统信息，{{appName}}收集的设备信息包括设备类型、设备型号、设备品牌、分辨率，操作系统信息。
        </p>
        <p>去标识化：指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</p>
        <p>匿名化：指个人信息经过处理无法识别特定自然人且不能复原的过程。</p>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'PrivacyProtocol',
    components: {},
    props: {},
    data() {
      return {
        PersonalInfoListUrl: `https://landing.happyky.cn/music-lib/personal-info-collect/list?type=${this.$route.query.type}`,
        SDKListUrl: `https://landing.happyky.cn/music-lib/sdk-list?type=${this.$route.query.type}`,
        appName: '',
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      if (this.$route.query.type && +this.$route.query.type == 2) {
        this.appName = '快乐跟唱';
      } else {
        this.appName = '快音AI写歌';
      }
      document.title = `${this.appName}隐私政策`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 16px;
  }
  h4 {
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    text-indent: 2em;
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  ul > li > span.bold {
    display: inline;
  }
  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
